<script lang="ts">
import {
  getAssetOnBoardingProgressById,
  postConnectAssetOnBoarding,
} from '@/api/assetsMgmt';
import { AssetFieldToState } from '@/utils/assetTypes';
import { customFailedMessage } from '@/utils/prompt';
import { ASSET_ON_BOARDING_PROGRESS } from '@/utils/workData/lookuptable';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'ConnectionProgress',
  components: {},
})
export default class extends Vue {
  @Prop({ required: true }) assetOnBoardingId!: string;
  @Prop({ required: true }) assetOnBoardingObj!: any;
  @Prop({ required: false }) assetFieldStates?: AssetFieldToState;

  /** Local variables */
  isConnecting: boolean = false;
  isConnected: boolean = false;
  isConnectionFail: boolean = false;
  noProgressData: boolean = false;
  progressStatuses: any = [];
  assetOnBoardingProgress: any = ASSET_ON_BOARDING_PROGRESS;
  triggerSource: any = this.$route.name;
  assetOnBoardingCroneJob: any = '';
  ON_BOARDING_PROGRESS_INTERVAL: number = 2000;
  progressRequestCounting: number = 0;
  progressRequestFailCountingThreshold: number = 1;
  connectionMessage: string = this.$t(
    'assetMgmt.assetOnBoardingConnecting'
  ) as string;

  created() {
    if (
      this.triggerSource === 'assetMgmtViewAsset' ||
      this.triggerSource === 'assetMgmtRegisterConnectingView'
    ) {
      // When connecting to an unassigned gateway -> GET /assetOnBoarding/{onBoardingId} endpoint
      // When view asset detailed page -> GET /assets/{assetId} -> different endpoint & object structure
      let assetOnBoardingId = this.assetOnBoardingObj.assetOnBoardingId
        ? this.assetOnBoardingObj.assetOnBoardingId
        : this.assetOnBoardingId;
      this.fetchAssetOnBoardingProgress(assetOnBoardingId);
      this.connectionMessage = this.$t(
        'assetMgmt.fetchingAssetOnBoardingStatuses'
      ) as string;
    }
  }

  beforeDestroy() {
    clearInterval(this.assetOnBoardingCroneJob);
  }

  /**
   * Connecting
   */
  async handleConnect() {
    if (!this.assetOnBoardingObj.gatewayId) {
      customFailedMessage(this.$t('assetMgmt.gatewayIdMandatory') as string);
      this.isConnecting = false;
      return;
    }

    if (!this.assetOnBoardingObj.preliminaryAssetId) {
      customFailedMessage(this.$t('assetMgmt.assetIdMandatory') as string);
      this.isConnecting = false;
      return;
    }

    // if (
    //   this.isAssetIDMandatory === true &&
    //   !this.assetOnBoardingObj.preliminaryAssetId
    // ) {
    //   customFailedMessage(this.$t('assetMgmt.assetIdMandatory') as string);
    //   this.isConnecting = false;
    //   return;
    // }

    // if (this.isVINMandatory === true && !this.assetOnBoardingObj.vinNumber) {
    //   customFailedMessage(this.$t('assetMgmt.vinMandatory') as string);
    //   this.isConnecting = false;
    //   return;
    // }

    let payloadData = {
      unassignedGatewayId: this.assetOnBoardingObj.gatewayId,
      companyAssetId: this.assetOnBoardingObj.preliminaryAssetId,
      // vinNumber: this.assetOnBoardingObj.vinNumber,
    };

    await postConnectAssetOnBoarding(this.assetOnBoardingId, payloadData).then(
      (res) => {
        if (!res) {
          customFailedMessage(
            this.$t('common.errorWithFetchingData') as string
          );
          return;
        }

        if (res.code === 200) {
          this.fetchAssetOnBoardingProgress(this.assetOnBoardingId);
        }

        if (
          res.code === 400 &&
          res.data.errors[0].code === 'ApiErrorCannotBeAssinged'
        ) {
          customFailedMessage(
            this.$t('assetMgmt.gatewayIdIsAlreadyAssigned') as string
          );
          this.isConnected = false;
          return;
        }

        if (res.code === 400) {
          customFailedMessage(
            this.$t('common.errorWithFetchingData') as string
          );
          this.isConnected = false;
        }
      }
    );
  }

  /** Start API calling by interval -> stop when receiving CONNECTED status */
  fetchAssetOnBoardingProgress(assetOnBoardingId: string) {
    this.isConnecting = true;
    this.assetOnBoardingCroneJob = setInterval(() => {
      this.retrieveAssetOnBoardingProgress(assetOnBoardingId);
    }, this.ON_BOARDING_PROGRESS_INTERVAL);
  }

  /** Retrieve asset on boarding progress after connecting to the unassigned gateway */
  async retrieveAssetOnBoardingProgress(assetOnBoardingId: string) {
    await getAssetOnBoardingProgressById(assetOnBoardingId).then((res) => {
      if (!res) return;

      if (res.code === 200) {
        this.progressStatuses = res.data;

        res.data.forEach((progressStatus: any) => {
          if (
            progressStatus.assetConnectingSubStatus ===
            this.assetOnBoardingProgress.AssConOnboardingSuccessful
          ) {
            clearInterval(this.assetOnBoardingCroneJob);
            this.isConnected = true;
          }

          if (
            progressStatus.assetConnectingSubStatus ===
            this.assetOnBoardingProgress.AssConOnBoardingFailed
          ) {
            clearInterval(this.assetOnBoardingCroneJob);
            this.isConnected = false;
            this.isConnectionFail = true;
          }
        });
      }

      // if (res.code === 400 && this.progressRequestCounting <= this.progressRequestFailCountingThreshold) {
      //     this.progressRequestCounting++
      //     return
      // }

      // if (res.code === 400) {
      //     customFailedMessage(this.$t('assetMgmt.retrieveAssetOnBoardingProgressFailed') as string);
      //     clearInterval(this.assetOnBoardingCroneJob)
      //     this.noProgressData = true
      // }
    });
  }

  /** Go back to connected asset */
  handleDoneSelfCheck() {
    this.$router.push('/asset-mgmt/index?activeName=connectedAssets');
  }
}
</script>

<template>
  <div class="self-check-container">
    <div
      v-if="!isConnected && !isConnecting && !isConnectionFail"
      class="self-check-content"
    >
      <el-button
        class="self-check-btn"
        id="selfcheckbtn"
        type="primary"
        @click="handleConnect"
      >
        {{ $t('assetMgmt.connectBtn') }}
      </el-button>

      <div class="self-check-btn-info" id="selfcheckinfo">
        {{ $t('assetMgmt.selfCheckBtnInfo') }}
      </div>
    </div>

    <div v-else>
      <div class="connecting-container">
        <div
          class="connecting-message"
          v-if="!isConnected && !isConnectionFail && !noProgressData"
        >
          {{ connectionMessage }}
        </div>
        <div
          v-if="!isConnected && !isConnectionFail && !noProgressData"
          class="dot-collision-animation"
        />
        <!-- <div class="connecting-message-fail" v-if="isConnectionFail">
                    {{ $t('assetMgmt.assetOnBoardingConnectionFail')}}
                </div> -->
      </div>
      <div
        class="self-check-done-container"
        v-if="!noProgressData && progressStatuses.length != 0"
      >
        <div
          class="self-check-items"
          v-for="(item, index) in progressStatuses"
          :key="index"
        >
          <div
            v-if="
              item.assetConnectingSubStatus ===
              assetOnBoardingProgress.AssConOnBoardingFailed
            "
            class="connection-status-message-fail"
          >
            - {{ $t(item.assetConnectingSubStatus) }}
          </div>
          <div
            v-if="
              item.assetConnectingSubStatus ===
              assetOnBoardingProgress.AssConOnboardingSuccessful
            "
            class="connection-status-message-success"
          >
            - {{ $t(item.assetConnectingSubStatus) }}
          </div>
          <div
            v-if="
              item.assetConnectingSubStatus !=
                assetOnBoardingProgress.AssConOnboardingSuccessful &&
              item.assetConnectingSubStatus !=
                assetOnBoardingProgress.AssConOnBoardingFailed
            "
            class="connection-status-message"
          >
            - {{ $t(item.assetConnectingSubStatus) }}
          </div>
        </div>
      </div>
      <div
        class="self-check-done-container"
        v-if="progressStatuses.length === 0"
      >
        <div class="self-check-item-no-progress">
          <div class="self-check-iteme">
            - {{ $t('assetMgmt.noProgressFoundContent') }}
          </div>
        </div>
      </div>

      <div class="self-check-done-container" v-if="noProgressData">
        <div class="self-check-item-no-progress">
          <div class="self-check-item">
            - {{ $t('assetMgmt.noProgressFoundContent') }}
          </div>
        </div>
      </div>

      <el-button
        v-if="isConnected"
        id="selfcehckdonebtn"
        class="self-check-done-btn"
        type="info"
        @click="handleDoneSelfCheck"
      >
        {{ $t('common.doneBtn') }}
      </el-button>
    </div>
  </div>
</template>

<style scoped>
.connection-status-message-success {
  color: green;
}

.connection-status-message-fail {
  color: red;
}

.connecting-message-fail {
  color: red;
  font-family: var(--fontRobotoMedium);
  font-size: 20px;
  margin-right: 25px;
}

.loading-animation {
  height: 0px;
  margin: 0 10px;
}

.connecting-container {
  display: flex;
  margin-bottom: 10px;
}

.connecting-message {
  font-family: var(--fontRobotoMedium);
  font-size: 20px;
  margin-right: 25px;
}

.self-check-btn {
  background-color: #349d99;
}

.self-check-btn:hover {
  background-color: #247c79 !important;
}

.self-check-btn:focus {
  background-color: #247c79 !important;
}

.self-check-btn:focus {
  background-color: #247c79 !important;
}

.self-check-container {
  display: flex;
}

.self-check-content {
  display: flex;
  margin-left: 48px;
  margin-top: 30px;
}

.self-check-btn-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  color: #c1c1c1;
}

.self-check-done-container {
  border: 0.1px solid #c1c1c1;
  border-radius: 4px;
  width: 500px;
  height: 340px;
  margin-bottom: 30px;
  overflow: auto;
}

.self-check-done-btn {
  margin-left: 300px;
  height: 40px;
  width: 200px;
}

.self-check-items {
  padding: 10px;
  font-size: 18px;
}

.self-check-item-no-progress {
  padding: 10px;
  font-size: 18px;
  color: red;
}

.el-button {
  height: 43px;
}

.el-select :deep(.el-input__inner) {
  width: 300px;
}

.form-item-container {
  width: 600px;
}

/* .form-item-container :deep(.el-input__inner) {
        margin-left: -90px;
    }

    .form-item-container :deep(.show-value) {
        margin-left: -90px;
    } */

.dot-collision-animation {
  margin-top: 15px;
  position: relative;
  bottom: 0;
  width: 5px;
  height: 5px;
  border-radius: 20px;
  background-color: black;
  color: black;
}

.dot-collision-animation::before,
.dot-collision-animation::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-collision-animation::before {
  left: -10px;
  width: 5px;
  height: 5px;
  border-radius: 20px;
  background-color: black;
  color: black;
  animation: dotCollisionBefore 2s infinite ease-in;
}

.dot-collision-animation::after {
  left: 10px;
  width: 5px;
  height: 5px;
  border-radius: 20px;
  background-color: black;
  color: black;
  animation: dotCollisionAfter 2s infinite ease-in;
  animation-delay: 1s;
}

@keyframes dotCollisionBefore {
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
}

@keyframes dotCollisionAfter {
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(10px);
  }
}
</style>

<style lang="scss" scoped>
:deep(.form-item-container) {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 40px;

  .form-title {
    width: 270px;
    margin-right: 22px;
    text-align: left;
    font-family: $font-Roboto-Regular;
    color: #373e41;
  }
  .show-title {
    font-size: 18px;
  }
}

.common-button {
  width: $formButtonWidth;
  height: 40px;
  font-size: 1.285714rem;
}
</style>
