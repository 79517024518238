import { ALL_CLAIMS_CODES } from '@/utils/workData/lookuptable';
import { RouteConfig } from 'vue-router';

export enum AssetManagementRouteNames {
  ConnectedAssets = 'connectedAssets',
  AssignedAssets = 'assignedAssets',
  EditAsset = 'assetMgmtEditAsset',
  ViewAsset = 'assetMgmtViewAsset',
}

const assetMgmtRoutes: RouteConfig = {
  path: '/asset-mgmt',
  component: () =>
    import(/* webpackChunkName: "layout" */ '@/components/layout/Layout.vue'),
  redirect: '/asset-mgmt/registerAssets',
  meta: {
    code: ALL_CLAIMS_CODES.AUTHRSC_MOD_ASSETS_MGMT,
  },
  children: [
    {
      path: 'index',
      component: () =>
        import(
          /* webpackChunkName: "assetManagement" */ '@/views/assetManagement/AssetManagement.vue'
        ),
      name: 'registeredAssets', // TODO Remove this one or the other one
      meta: {
        title: 'route.assetMgmt.index',
        icon: 'assetMgmt',
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_REGISTERED_ASSETS,
      },
    },
    {
      path: '/asset-mgmt/registeredAssets',
      component: () =>
        import(
          /* webpackChunkName: "assetManagement" */ '@/views/assetManagement/AssetManagement.vue'
        ),
      name: 'registeredAssets', // TODO Remove this one or the other one
      meta: {
        title: 'route.assetMgmt.index',
        hidden: true,
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_REGISTERED_ASSETS,
      },
    },
    {
      path: '/asset-mgmt/connectedAssets',
      component: () =>
        import(
          /* webpackChunkName: "assetManagement" */ '@/views/assetManagement/AssetManagement.vue'
        ),
      name: AssetManagementRouteNames.ConnectedAssets,
      meta: {
        hidden: true,
        title: 'route.assetMgmt.index',
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_CONNECTED_ASSETS,
      },
    },
    {
      path: '/asset-mgmt/assignedAssets',
      component: () =>
        import(
          /* webpackChunkName: "assetManagement" */ '@/views/assetManagement/AssetManagement.vue'
        ),
      name: AssetManagementRouteNames.AssignedAssets,
      meta: {
        hidden: true,
        title: 'route.assetMgmt.index',
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_ASSIGNED_ASSETS,
      },
    },
    {
      path: 'registering-asset',
      component: () =>
        import(
          /* webpackChunkName: "assetManagement" */ '@/views/assetManagement/register/RegisteringAsset.vue'
        ),
      name: 'assetMgmtRegisterAsset',
      meta: {
        title: 'route.assetMgmt.registerAsset',
        claim: ALL_CLAIMS_CODES.AUTHRSC_ACTION_ASSET_REGISTER,
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_REGISTERED_ASSETS,
        hidden: true,
      },
    },
    {
      path: 'connect/edit/:assetOnBoardingId',
      component: () =>
        import(
          /* webpackChunkName: "assetManagement" */ '@/views/assetManagement/connect/EditConnectAsset.vue'
        ),
      name: 'assetMgmtConnectAssetEdit',
      props: true,
      meta: {
        title: 'route.assetMgmt.index',
        claim: ALL_CLAIMS_CODES.AUTHRSC_ACTION_ASSET_CONNECT,
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_REGISTERED_ASSETS,
        hidden: true,
      },
    },
    {
      path: 'view/:assetId',
      component: () =>
        import(
          /* webpackChunkName: "assetManagement" */ '@/views/assetManagement/AssetView.vue'
        ),
      name: AssetManagementRouteNames.ViewAsset,
      props: true,
      meta: {
        title: 'route.assetMgmt.index',
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_CONNECTED_ASSETS,
        hidden: true,
      },
    },
    {
      path: '/asset-mgmt/edit/:assetId',
      component: () =>
        import(
          /* webpackChunkName: "assetManagement" */ '@/views/assetManagement/AssetEdit.vue'
        ),
      name: AssetManagementRouteNames.EditAsset,
      props: true,
      meta: {
        title: 'route.assetMgmt.index',
        claim: ALL_CLAIMS_CODES.AUTHRSC_ACTION_CONNECTED_ASSET_UPDATE,
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_CONNECTED_ASSETS,
        hidden: true,
      },
    },
    {
      path: 'register/view/:assetId',
      component: () =>
        import(
          /* webpackChunkName: "assetManagement" */ '@/views/assetManagement/register/RegisterAssetView.vue'
        ),
      name: 'assetMgmtRegisterView',
      props: true,
      meta: {
        title: 'route.assetMgmt.index',
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_REGISTERED_ASSETS,
        hidden: true,
      },
    },
    {
      path: 'register-connecting/view/:assetId',
      component: () =>
        import(
          /* webpackChunkName: "assetManagement" */ '@/views/assetManagement/register/RegisteredAssetConnectView.vue'
        ),
      name: 'assetMgmtRegisterConnectingView',
      props: true,
      meta: {
        title: 'route.assetMgmt.index',
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_REGISTERED_ASSETS,
        hidden: true,
      },
    },
    {
      path: 'register/edit/:assetId',
      component: () =>
        import(
          /* webpackChunkName: "assetManagement" */ '@/views/assetManagement/register/RegisterAssetEdit.vue'
        ),
      name: 'assetMgmtRegisterEdit',
      props: true,
      meta: {
        title: 'route.assetMgmt.index',
        claim: ALL_CLAIMS_CODES.AUTHRSC_ACTION_REGISTERED_ASSET_UPDATE,
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_REGISTERED_ASSETS,
        hidden: true,
      },
    },
    {
      path: 'assign-asset',
      component: () =>
        import(
          /* webpackChunkName: "assetManagement" */ '@/views/assetManagement/connect/AssigningAsset.vue'
        ),
      name: 'assetMgmtAssignAsset',
      meta: {
        title: 'route.assetMgmt.assignAsset',
        claim: ALL_CLAIMS_CODES.AUTHRSC_ACTION_ASSET_ASSIGN,
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_CONNECTED_ASSETS,
        hidden: true,
      },
    },
  ],
};

export default assetMgmtRoutes;
