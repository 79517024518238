<script setup lang="ts">
import { AssetResponse } from '@/api/assetsMgmt';
import { UUID } from '@/api/common';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import { useRoute, useRouter } from '@/composables/router';
import i18n from '@/lang';
import { useAssetInfoQuery, useUpdateAssetMutation } from '@/query/assets';
import { AssetManagementRouteNames } from '@/router/modules/assetMgmt';
import { customFailedMessage, customSuccessMessage } from '@/utils/prompt';
import { AssetLifecycle } from '@/utils/workData/lookuptable';
import { computed, ref, watch } from 'vue';
import EditAssetForm from './EditAssetForm.vue';

const route = useRoute();
const routeAssetId = computed((): UUID | undefined =>
  typeof route.value.params.assetId === 'string'
    ? route.value.params.assetId
    : undefined
);

const initialAsset = useAssetInfoQuery(routeAssetId);
const asset = ref<AssetResponse>();

watch(
  initialAsset.data,
  () => {
    if (!initialAsset.data.value) return;

    asset.value = initialAsset.data.value;
  },
  { deep: true, immediate: true }
);

const router = useRouter();

const updateMutation = useUpdateAssetMutation(
  () => {
    customSuccessMessage(i18n.t('common.changesSaved'));
    router.push({
      name:
        initialAsset.data.value?.status === AssetLifecycle.Connected
          ? AssetManagementRouteNames.ConnectedAssets
          : AssetManagementRouteNames.AssignedAssets,
    });
  },
  (e) => {
    console.error(e);
    customFailedMessage(i18n.t('common.errorWithFetchingData'));
  }
);
async function updateAsset() {
  if (routeAssetId.value === undefined || asset.value === undefined) {
    throw new Error('routeAssetId and asset has to be defined');
  }

  updateMutation.mutate({ assetId: routeAssetId.value, asset: asset.value });
}

const isFormValid = ref<boolean>(false);
</script>

<template>
  <WidgetCard
    v-loading="initialAsset.isLoading.value === true"
    style="height: 100%"
    :show-back-button="true"
    :default-title="`Edit ${initialAsset.data.value?.assetDataCollectionId}`"
    :float-actions-right="true"
  >
    <template #actions>
      <el-button type="plain" @click="updateAsset" :disabled="!isFormValid">
        <svg-icon class="save-icon" name="save" />
        {{ $t('assetMgmt.save') }}
      </el-button>
    </template>
    <EditAssetForm
      v-if="asset"
      ref="assetFormRef"
      :modelValue="asset"
      @update:modelValue="asset = $event"
      @is-valid="isFormValid = $event"
    />
  </WidgetCard>
</template>
