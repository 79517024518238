<script lang="ts">
import { getAccessibleAssets } from '@/api/accessibleAssets';
import { getConnectedAssets } from '@/api/assetsMgmt';
import { ActiveContext, useActiveContext } from '@/auth/context';
import PureTable from '@/components/table/PureTable.vue';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import {
  addFilter,
  Filter,
  FilterOperator,
  Pagination,
  QueryParameter,
  Sorter,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import { ASSET_STATUS_FIELD, CREATED_ON } from '@/utils/constants';
import { fetchLastCommunicationTimes } from '@/utils/lastAssetCommunicationTime';
import { customFailedMessage } from '@/utils/prompt';
import {
  CONNECTED_ASSETS_COLS,
  CONNECTED_ASSETS_SEARCH_OPTION_COLS,
} from '@/utils/workData/assetsMgmt';
import {
  AssetLifecycle,
  ASSET_CONNECT_STATUS,
  COMPANY_TYPE,
} from '@/utils/workData/lookuptable';
import moment from 'moment';
import { Ref, unref } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

interface TableRowData {
  id: string;
  assetDataCollectionId: string;
  translatedAssetType: string;
  preliminaryAssetId: string;
  vinNumber: string;
  connectionHandlerCompanyName?: string;
  controllerId?: string;
  gatewayId: string;
  installTime: string;
  lastCommunicationTime?: string;
  manufactureDate?: string;
  productModelCode: string;
  productModelNumber: string;
  serialNumber?: string;
  translatedServiceRegion: string;
  translatedHyvaRegion: string;
}

@Component({
  name: 'ConnectedAssets',
  components: {
    'select-table-header': SelectTableHeader,
    'pure-table': PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  assetSerial: string = '';
  connectedAssetsCols = CONNECTED_ASSETS_COLS.map((item) => ({
    ...item,
    label: this.$t(item.label),
  }));
  tableList: TableRowData[] = [];
  total: number = 0;
  assetConnectStatus: any = ASSET_CONNECT_STATUS;
  isConnectedAssetsLoading: boolean = false;
  allProductModels: any = [];
  pageSize = UserModule.gridPageSize;
  currentPage: number = 1;
  connectedAssetsSearchFields: any = CONNECTED_ASSETS_SEARCH_OPTION_COLS;
  tableElementPath: string = 'view';
  sortAndOrderData: any = {
    sortBy: null,
    order: null,
  };
  searchParams: any = {
    reference: null,
    value: null,
  };
  useAccessibleAssets: boolean = false;
  defaultSortByReference: string = 'createdOn';
  defaultSortOrder: string = 'DESC';

  context!: Ref<ActiveContext>;

  created() {
    this.context = useActiveContext();
    this.useAccessibleAssets = [
      COMPANY_TYPE.BodyBuilder.toString(),
      COMPANY_TYPE.Dealer.toString(),
    ].includes(UserModule.companyType);
    this.prepareDefaultInitialization();
  }

  prepareDefaultInitialization() {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      1,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchConnectedAssetsData(finalUrlParamsForSearch);
  }

  /** Filter by sort event */
  fetchConnectedAssetsDataBySortEvent(sortBy: any, order: any) {
    order != ''
      ? (this.sortAndOrderData.sortBy = sortBy)
      : (this.sortAndOrderData.sortBy = null);
    order != ''
      ? (this.sortAndOrderData.order = order)
      : (this.sortAndOrderData.order = null);
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchConnectedAssetsData(finalUrlParamsForSearch);
  }

  fetchConnectedAssetsDataByPageSelection(page: number, pageSize: number) {
    this.currentPage = page;
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      page,
      pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchConnectedAssetsData(finalUrlParamsForSearch);
  }

  handleSearching(col: string, order: string) {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchConnectedAssetsData(finalUrlParamsForSearch);
  }

  /** Generate request URL by multiple factors */
  generateRequestUrlWithParams(
    pageNumber: any,
    pageSize: number,
    searchParams: any,
    sortBy: any,
    order: any
  ) {
    let finalUrl = '/assets';
    let searchFieldName = searchParams ? searchParams.reference : null;
    let searchFieldValue =
      searchParams.value && searchParams.reference != 'installTime'
        ? encodeURIComponent(searchParams.value)
        : searchParams.value && searchParams.reference === 'installTime'
        ? moment(searchParams.value).format('YYYY-MM-DD')
        : null;

    pageNumber
      ? (finalUrl += `?page=${pageNumber}`)
      : (finalUrl += `?page=${1}`);
    pageNumber
      ? (finalUrl += `&size=${pageSize}`)
      : (finalUrl += `&size=${this.pageSize}`);

    finalUrl += `&status=LCYASS_CONNECTED`;

    if (searchFieldName && searchFieldValue) {
      finalUrl += `&searchFieldName=${searchFieldName}&searchFieldValues=${searchFieldValue}`;
    }

    if (sortBy && order) {
      finalUrl += `&sortBy=${sortBy}&order=${order}_NULLS_LAST`;
    } else {
      finalUrl += `&sortBy=${this.defaultSortByReference}&order=${this.defaultSortOrder}`;
    }

    return finalUrl;
  }

  /**
   * Fetch connected assets accordingly with company type:
   * - for hyvaadmin: from asset on boarding
   * - for body builder: from accessible assets by org id
   * @param finalUrl
   */
  async fetchConnectedAssetsData(finalUrl: string) {
    try {
      this.isConnectedAssetsLoading = true;
      const res = this.useAccessibleAssets
        ? await getAccessibleAssets(
            this.prepareQueryParameterForAccessibleAssets(),
            UserModule.organizationId
          )
        : await getConnectedAssets(finalUrl);
      if (!res || res.code !== 200) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }
      this.total = res.data.total;
      const lastCommunicationTimes = await fetchLastCommunicationTimes(
        res.data.assets.map((x) => x.id),
        unref(this.context)
      );
      this.tableList = res.data.assets.map(
        (x) =>
          ({
            id: x.id,
            assetDataCollectionId: x.assetDataCollectionId,
            translatedAssetType: this.$t(x.assetType),
            companyAssetId: x.companyAssetId,
            connectionHandlerCompanyName:
              'connectionHandlerCompanyName' in x
                ? x.connectionHandlerCompanyName
                : undefined,
            connectionStatus: x.assetStatus,
            controllerId: x.controllerId,
            gatewayId: x.gatewayId,
            installTime: 'installTime' in x ? x.installTime : undefined,
            lastCommunicationTime: lastCommunicationTimes.get(x.id),
            manufactureDate: x.manufactureDate,
            productModelCode: x.productModelCode,
            productModelNumber: x.productModelNumber,
            serialNumber: x.serialNumber,
            timezone: x.timezone,
            vinNumber: x.vinNumber,
            preliminaryAssetId: x.preliminaryAssetId,
            translatedServiceRegion: this.$t(x.serviceRegion),
            translatedHyvaRegion: this.$t(x.hyvaRegion),
          } as TableRowData)
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.isConnectedAssetsLoading = false;
    }
  }

  prepareQueryParameterForAccessibleAssets(): QueryParameter {
    const defaultFilterForRegisteredAsset: Filter = {
      name: ASSET_STATUS_FIELD,
      operator: FilterOperator.IN,
      value: [
        AssetLifecycle.Connected,
        AssetLifecycle.Unassigned,
        AssetLifecycle.Installed,
      ],
    };
    const sorter: Sorter =
      this.sortAndOrderData.sortBy != null
        ? {
            field: this.sortAndOrderData.sortBy,
            order: this.sortAndOrderData.order,
          }
        : { field: CREATED_ON, order: SorterOrder.DESC };
    const pagination: Pagination = {
      page: this.currentPage,
      size: this.pageSize,
    };
    let queryParameter: QueryParameter = {
      filters: [defaultFilterForRegisteredAsset],
      sorters: [sorter],
      pagination: pagination,
    };
    if (this.searchParams.value != null) {
      const operator: FilterOperator = this.searchParams.operator
        ? this.searchParams.operator
        : FilterOperator.LIKE;
      const filter: Filter = {
        name: this.searchParams.reference,
        operator: operator,
        value: [this.searchParams.value],
      };
      queryParameter = addFilter(queryParameter, filter);
    }
    return queryParameter;
  }
}
</script>

<template>
  <div class="app-container">
    <select-table-header
      style="margin-bottom: 20px"
      :searchFieldOptions="connectedAssetsSearchFields"
      :cols="connectedAssetsCols"
      :searchParams="searchParams"
      @search-event="handleSearching"
    />
    <pure-table
      v-loading="isConnectedAssetsLoading"
      :element-loading-text="$t('assetsModule.assetsAreLoading')"
      :tableList="tableList"
      :total="total"
      :cols="connectedAssetsCols"
      :viewPagePath="tableElementPath"
      @handle-page="fetchConnectedAssetsDataByPageSelection"
      @handle-sort-change="fetchConnectedAssetsDataBySortEvent"
    />
  </div>
</template>

<style lang="scss" scoped>
.total_items {
  font-size: 16px;
  font-family: $font-Roboto-Bold;
  color: #373e41;
  line-height: 28px;
  opacity: 0.6;
  span {
    font-size: 24px;
    line-height: 28px;
    opacity: 0.8;
    vertical-align: bottom;
  }
}
</style>
